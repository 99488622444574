@use 'variables';

.ui__button-primary{
  background: variables.$button;
  border-radius: 200px;
  padding: 12px 16px;
  color: variables.$primaryText;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
}

.ui__button-add{
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: variables.$primaryText;
  background: variables.$button;
  border-radius: 200px;
  padding: 8px 16px;
  display: flex;
  align-items: center;

  svg{
    margin-right: 12px;
  }
}

.ui__button-archive, .ui__button-back{
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: variables.$primaryText;
  display: flex;
  align-items: center;
  background: variables.$button;
  border-radius: 200px;
  padding: 8px 16px;

  svg{
    margin-right: 12px;
  }
}

.ui__button-recover{
  padding: 12px 20px;
  border-radius: 200px;
  display: inline-flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  width: 40px;

  svg{
    width: 16px;
    height: 16px;
    min-width: 16px;
  }

  .label{
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: variables.$secondaryText;
    margin-left: 13px;
    margin-bottom: -2px;
    text-transform: uppercase;
    transition: all 0.3s ease;
    opacity: 0;
  }

  &.hover{
    background: variables.$buttonPrimary;

    .label{
      opacity: 1;
    }
  }
}

.fields__relation-select .hidden{
  display: none;
  visibility: hidden;
}