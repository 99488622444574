$background: rgb(235, 237, 239);
$sideNav:  #fff;
$border: #E8EAED;
$borderSecondary: #d0d0d0;
$primaryText: #000;
$secondaryText: #000;
$navText: #000;
$navHighlight: #E8EAED;
$buttonPrimary: #313D49;
$asideInput: #E8EAED;
$itemAside: #212A33;
$button:  #D8DFF4;