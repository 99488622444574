.add-review {
}

.form__add-review {
  display: flex;
  background: #fff;
  padding: 18px 17px;
  border-radius: 6px;

  .thumb {
    width: 30%;
  }

  .meta {
    width: 70%;
    padding-left: 18px;
  }

  label {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    display: block;
    margin-bottom: 8px;
  }

  [type="file"] {
    width: 10px !important;
  }

  input, textarea, select {
    background: #E8EAED;
    border: 1px solid #e5e7ea;
    display: block;
    width: 100%;
    border-radius: 6px;
    margin-bottom: 8px;
    padding: 4px 12px;
  }

  textarea {
    height: 97px;
    max-width: 100%;
    min-width: 100%;
  }

  input, select {
    height: 42px;
  }

  .thumb__window {
    margin-bottom: 7px;
  }

  [type="submit"] {
    background: #D8DFF4;
    border: 0;
    border-radius: 6px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
    font-size: 14px;
    font-weight: 500;
    margin-top: 24px;
    margin-left: auto;
  }
}

.writer__review {
  display: flex;
  background: #fff;
  border-radius: 6px;
  padding: 18px;
  margin-bottom: 18px;

  .thumb {
    max-width: 80px;
    margin-right: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    width: 80px;
    height: 80px;
  }

  .meta {
    max-width: calc(100% - 104px);
    width: 100%;
  }

  .rating {
    list-style: none;
    display: flex;

    .disabled {
      svg {
        fill: #888888;
      }
    }

    svg {
      width: 14px;
      height: 14px;
      fill: gold;
    }
  }

  .name {
    font-size: 14px;
    font-weight: 500;
  }

  .text {
    font-size: 14px;
    color: #232323;
    margin-bottom: 8px;
  }

  .date {
    font-size: 14px;
    color: #484747;
    margin-left: auto;
  }

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .delete {
    cursor: pointer;
  }
}

.article__reviews {
  .review__item {
    background: #fff;
    border-radius: 6px;
    margin-bottom: 8px;
    font-size: 14px;
    padding: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.approved{
      background: #9ef8ae69;
    }

    div{
      margin-bottom: 4px;
    }

    .controls{
      display: flex;
      align-items: center;
    }

    .approve{
      color: #ffffff;
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #0E9C60;
      border-radius: 4px;
      margin-right: 10px;
    }

    span{
      font-weight: 500;
    }
  }
}