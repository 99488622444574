@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Medium.eot');
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
  url('../fonts/Montserrat-Medium.eot') format('embedded-opentype'),
  url('../fonts/Montserrat-Medium.woff2') format('woff2'),
  url('../fonts/Montserrat-Medium.woff') format('woff'),
  url('../fonts/Montserrat-Medium.ttf') format('truetype'),
  url('../fonts/Montserrat-Medium.svg') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Light.eot');
  src: local('Montserrat Light'), local('Montserrat-Light'),
  url('../fonts/Montserrat-Light.eot') format('embedded-opentype'),
  url('../fonts/Montserrat-Light.woff2') format('woff2'),
  url('../fonts/Montserrat-Light.woff') format('woff'),
  url('../fonts/Montserrat-Light.ttf') format('truetype'),
  url('../fonts/Montserrat-Light.svg') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Bold.eot');
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
  url('../fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Montserrat-Bold.woff2') format('woff2'),
  url('../fonts/Montserrat-Bold.woff') format('woff'),
  url('../fonts/Montserrat-Bold.ttf') format('truetype'),
  url('../fonts/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Black.eot');
  src: local('Montserrat Black'), local('Montserrat-Black'),
  url('../fonts/Montserrat-Black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Montserrat-Black.woff2') format('woff2'),
  url('../fonts/Montserrat-Black.woff') format('woff'),
  url('../fonts/Montserrat-Black.ttf') format('truetype'),
  url('../fonts/Montserrat-Black.svg#Montserrat-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-SemiBold.eot');
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
  url('../fonts/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Montserrat-SemiBold.woff2') format('woff2'),
  url('../fonts/Montserrat-SemiBold.woff') format('woff'),
  url('../fonts/Montserrat-SemiBold.ttf') format('truetype'),
  url('../fonts/Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Regular.eot');
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
  url('../fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Montserrat-Regular.woff2') format('woff2'),
  url('../fonts/Montserrat-Regular.woff') format('woff'),
  url('../fonts/Montserrat-Regular.ttf') format('truetype'),
  url('../fonts/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

