@use "variables";

#layout-container{
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  background: variables.$background;
  width: 100%;
  overflow-x: hidden;
  min-height: 100vh;
}

#layout-aside{
  width: 260px;
  padding: 24px 16px 8px;
}

#layout-main{
  width: calc(100% - 260px);
  padding: 64px 125px;
  position: relative;

  &.with-header{
    padding: 120px 125px 64px;
  }
}


@media screen and (max-width: 1500px) {
  #layout-main{
    padding: 64px 50px;

    &.with-header{
      padding: 120px 50px 64px;
    }
  }
}

.ui__main-head{
  margin-bottom: 68px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:empty{
    margin-bottom: 0;
    display: none;
  }

  .buttons{
    display: flex;
    align-items: center;

    *:not(:last-child){
      margin-right: 7px;
    }
  }
}

.ui__sticky-header{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: variables.$sideNav;
  padding: 12px 125px;
  border-bottom: 1px solid variables.$border;
  display: flex;
  align-items: center;

  .ui__button-back{
    margin-right: 24px;
  }
}

.ui__2-col {
  display: grid;
  grid-template-columns: 1fr 0.4fr;
  grid-template-rows: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 0px;

  &.--inverse{
    grid-template-columns: 0.4fr 1fr;
  }
}

.ui__2-col--main { grid-area: 1 / 1 / 2 / 2; }

.ui__2-col--aside {
  grid-area: 1 / 2 / 2 / 3;
  background: #fff;
  padding: 12px;
  box-shadow: 0px 1px 1px rgba(0,0,0,.1);
  border-radius: 8px;

  .ui__form-controls{
    [type=submit]{
      margin-right: 0;
      max-width: 100%;
    }
  }
}

.fieldSet{
  background: #fff;
  padding: 12px;
  box-shadow: 1px 1px 7px rgba(0,0,0, .1);
  border-radius: 8px;
  margin-bottom: 24px;
  position: relative;

  &.collapsed{
    .ui__form-row:not(.fieldset__name){
      display: none;
    }

    .collapse{
      transform: rotate(0deg);
    }
  }

  .collapse{
    position: absolute;
    top: 5px;
    right: 18px;
    transform: rotate(180deg) translateY(-6px);
    transition: .3s;
  }

  .children-container{
    border: 1px dashed #313d49;
    background: rgba(255, 255, 255, 0.01);
    padding: 12px 12px 0;
    border-radius: 8px;
    margin-bottom: 15px;
    min-height: 60px;
  }
}