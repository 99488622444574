.seo-meta__fields.-hidden{
  display: none;
}

.seo-meta__controls{
  display: flex;
  justify-content: space-between;
  align-items: center;

  .lang-switch{
    display: flex;
    background: #fff;
    padding: 3px;
    border: 1px solid #d0d0d1;
    border-radius: 6px;

    .lang{
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45px;
      height: 30px;
      color: #000000;
      border-radius: 6px;
      cursor: pointer;

      &.active{
        background: #cfdbfe;
      }
    }
  }
}  