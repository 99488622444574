@charset "UTF-8";
/* Reset and base styles  */
* {
  padding: 0px;
  margin: 0px;
  border: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

:focus,
:active {
  /*outline: none;*/
}

a:focus,
a:active {
  /* outline: none;*/
}

/* Links */
a, a:link, a:visited {
  /* color: inherit; */
  text-decoration: none;
  /* display: inline-block; */
}

a:hover {
  /* color: inherit; */
  text-decoration: none;
}

/* Common */
aside, nav, footer, header, section, main {
  display: block;
}

h1, h2, h3, h4, h5, h6, p {
  font-size: inherit;
  font-weight: inherit;
}

ul, ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

img, svg {
  max-width: 100%;
  height: auto;
}

address {
  font-style: normal;
}

/* Form */
input, textarea, button, select {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
}

input::-ms-clear {
  display: none;
}

button, input[type=submit] {
  display: inline-block;
  box-shadow: none;
  background-color: transparent;
  background: none;
  cursor: pointer;
}

input:focus, input:active,
button:focus, button:active {
  outline: none;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

label {
  cursor: pointer;
}

legend {
  display: block;
}

body.login {
  background: url("/public/frontend/img/admin.jpg") 100% 0;
  background-size: cover;
  overflow: hidden;
}
body.login p, body.login label, body.login h1 {
  color: #000000;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}
@keyframes swell {
  0%, 100% {
    transform: translate3d(0, -40px, 0);
  }
  50% {
    transform: translate3d(0, -10px, 0);
  }
}
.endWave {
  display: none;
}

.login__container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 15px;
}

.login__form {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.08);
  border-radius: 40px;
  padding: 44px 44px 62px 44px;
  max-width: 539px;
  width: 100%;
}
.login__form label {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 13px;
}
.login__form input {
  background: #FFFFFF;
  border-radius: 9px;
  width: 100%;
  font-size: 14px;
  color: #000000;
  padding: 16px 25px 13px;
  margin-bottom: 24px;
  border: 1px solid #ADADAD;
}
.login__form input::placeholder {
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #808080;
}
.login__form input:focus {
  border: 1px solid #0E9C60;
}
.login__form [type=submit] {
  background: #0E9C60;
  box-shadow: 0px 4px 19px rgba(119, 147, 65, 0.3);
  border-radius: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  height: 56px;
  margin-top: 24px;
}
.login__form .alert-danger {
  text-align: center;
  font-weight: 300;
  font-size: 14px;
  color: #ed143d;
  margin-bottom: 12px;
}

.login__heading {
  font-weight: 500;
  font-size: 55px;
  line-height: 82px;
  margin-bottom: 32px;
  text-align: center;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Medium.eot");
  src: local("Montserrat Medium"), local("Montserrat-Medium"), url("../fonts/Montserrat-Medium.eot") format("embedded-opentype"), url("../fonts/Montserrat-Medium.woff2") format("woff2"), url("../fonts/Montserrat-Medium.woff") format("woff"), url("../fonts/Montserrat-Medium.ttf") format("truetype"), url("../fonts/Montserrat-Medium.svg") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Light.eot");
  src: local("Montserrat Light"), local("Montserrat-Light"), url("../fonts/Montserrat-Light.eot") format("embedded-opentype"), url("../fonts/Montserrat-Light.woff2") format("woff2"), url("../fonts/Montserrat-Light.woff") format("woff"), url("../fonts/Montserrat-Light.ttf") format("truetype"), url("../fonts/Montserrat-Light.svg") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Bold.eot");
  src: local("Montserrat Bold"), local("Montserrat-Bold"), url("../fonts/Montserrat-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-Bold.woff2") format("woff2"), url("../fonts/Montserrat-Bold.woff") format("woff"), url("../fonts/Montserrat-Bold.ttf") format("truetype"), url("../fonts/Montserrat-Bold.svg#Montserrat-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Black.eot");
  src: local("Montserrat Black"), local("Montserrat-Black"), url("../fonts/Montserrat-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-Black.woff2") format("woff2"), url("../fonts/Montserrat-Black.woff") format("woff"), url("../fonts/Montserrat-Black.ttf") format("truetype"), url("../fonts/Montserrat-Black.svg#Montserrat-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-SemiBold.eot");
  src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"), url("../fonts/Montserrat-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-SemiBold.woff2") format("woff2"), url("../fonts/Montserrat-SemiBold.woff") format("woff"), url("../fonts/Montserrat-SemiBold.ttf") format("truetype"), url("../fonts/Montserrat-SemiBold.svg#Montserrat-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Regular.eot");
  src: local("Montserrat Regular"), local("Montserrat-Regular"), url("../fonts/Montserrat-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-Regular.woff2") format("woff2"), url("../fonts/Montserrat-Regular.woff") format("woff"), url("../fonts/Montserrat-Regular.ttf") format("truetype"), url("../fonts/Montserrat-Regular.svg#Montserrat-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
html {
  font-family: "Montserrat", "sans-serif";
  color: #000;
}

h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 30px;
}

#layout-container {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  background: rgb(235, 237, 239);
  width: 100%;
  overflow-x: hidden;
  min-height: 100vh;
}

#layout-aside {
  width: 260px;
  padding: 24px 16px 8px;
}

#layout-main {
  width: calc(100% - 260px);
  padding: 64px 125px;
  position: relative;
}
#layout-main.with-header {
  padding: 120px 125px 64px;
}

@media screen and (max-width: 1500px) {
  #layout-main {
    padding: 64px 50px;
  }
  #layout-main.with-header {
    padding: 120px 50px 64px;
  }
}
.ui__main-head {
  margin-bottom: 68px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ui__main-head:empty {
  margin-bottom: 0;
  display: none;
}
.ui__main-head .buttons {
  display: flex;
  align-items: center;
}
.ui__main-head .buttons *:not(:last-child) {
  margin-right: 7px;
}

.ui__sticky-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  padding: 12px 125px;
  border-bottom: 1px solid #E8EAED;
  display: flex;
  align-items: center;
}
.ui__sticky-header .ui__button-back {
  margin-right: 24px;
}

.ui__2-col {
  display: grid;
  grid-template-columns: 1fr 0.4fr;
  grid-template-rows: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 0px;
}
.ui__2-col.--inverse {
  grid-template-columns: 0.4fr 1fr;
}

.ui__2-col--main {
  grid-area: 1/1/2/2;
}

.ui__2-col--aside {
  grid-area: 1/2/2/3;
  background: #fff;
  padding: 12px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.ui__2-col--aside .ui__form-controls [type=submit] {
  margin-right: 0;
  max-width: 100%;
}

.fieldSet {
  background: #fff;
  padding: 12px;
  box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 24px;
  position: relative;
}
.fieldSet.collapsed .ui__form-row:not(.fieldset__name) {
  display: none;
}
.fieldSet.collapsed .collapse {
  transform: rotate(0deg);
}
.fieldSet .collapse {
  position: absolute;
  top: 5px;
  right: 18px;
  transform: rotate(180deg) translateY(-6px);
  transition: 0.3s;
}
.fieldSet .children-container {
  border: 1px dashed #313d49;
  background: rgba(255, 255, 255, 0.01);
  padding: 12px 12px 0;
  border-radius: 8px;
  margin-bottom: 15px;
  min-height: 60px;
}

.ui__side-nav {
  background: #fff;
  border-right: 1px solid #E8EAED;
  position: relative;
}
.ui__side-nav .collapse {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 37px;
  right: -12px;
  padding: 8px;
  border-radius: 50%;
  border: 1px solid #E8EAED;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  z-index: 999;
}
.ui__side-nav .brand {
  display: flex;
  align-items: flex-end;
  padding-bottom: 12px;
  border-bottom: 1px solid #E8EAED;
  margin-bottom: 40px;
}
.ui__side-nav .company .name {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #000;
  max-width: 60%;
  margin: 0 auto 12px;
}
.ui__side-nav .company .label {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #000;
  text-align: center;
}

.ui__navigation .nav-item {
  display: flex;
  align-items: center;
  height: 44px;
  padding: 12px;
  margin-bottom: 8px;
}
.ui__navigation .nav-item svg {
  max-width: 16px;
}
.ui__navigation .nav-item.active {
  background: #E8EAED;
  border-radius: 8px;
}
.ui__navigation .nav-item.active .label {
  color: #000;
}
.ui__navigation .nav-item.active svg .stroke {
  stroke: #000;
}
.ui__navigation .nav-item.active svg .fill {
  fill: #000;
}
.ui__navigation .label {
  margin-left: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000;
}

.ui__listing {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  border-radius: 8px 8px 0px 0px;
  overflow: hidden;
}

.ui__listing-head {
  background: #fff;
  border-bottom: 1px solid #E8EAED;
}

.ui__listing-th {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000;
  padding: 10px 12px;
  text-transform: capitalize;
  text-align: left;
}

.ui__listing-item {
  padding: 12px;
  border-bottom: 1px solid #E8EAED;
  transition: all 0.3s;
  background: #fff;
}
.ui__listing-item:hover {
  background: #D8DFF4;
}

.ui__listing-cell {
  padding: 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000;
}

.ui__listing-edit {
  text-align: right;
  white-space: nowrap;
}

.ui__listing-button {
  padding: 12px 20px;
  border-radius: 200px;
  display: inline-flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  width: 40px;
}
.ui__listing-button svg {
  width: 16px;
  height: 16px;
  min-width: 16px;
}
.ui__listing-button .label {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #000;
  margin-left: 13px;
  margin-bottom: -2px;
  text-transform: uppercase;
  transition: all 0.3s ease;
  opacity: 0;
}
.ui__listing-button.hover {
  background: #fff;
}
.ui__listing-button.hover .label {
  opacity: 1;
}

.ui__lang-switcher {
  display: flex;
  background: #E8EAED;
  padding: 3px;
  border: 1px solid #d0d0d1;
  border-radius: 6px;
}
.ui__lang-switcher li {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 30px;
  color: #000000;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 6px;
  cursor: pointer;
}
.ui__lang-switcher li.show {
  background: #b4c0e2;
}

.switch-item {
  display: block;
}

.switch-item .label {
  margin-left: 4px;
  vertical-align: middle;
  font-size: 16px;
  color: #000;
}

.switch-item ~ .control[type=checkbox] {
  appearance: none;
  position: relative;
  width: 52px;
  height: 34px;
  padding: 3px;
  border-radius: 34px;
  background-color: #aaa;
  vertical-align: middle;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.1, 1.4);
  border: 1px solid #E8EAED;
  box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.1);
}

.switch-item ~ .control[type=checkbox]:after {
  content: "";
  position: relative;
  display: block;
  left: 0;
  width: 60.9%;
  height: 100%;
  border-radius: 28px;
  background-color: #fff;
  transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), padding 0.3s ease, margin 0.3s ease;
  box-sizing: content-box;
}

.switch-item ~ .control[type=checkbox]:active:after {
  padding-right: 8px;
}

.switch-item ~ .control[type=checkbox]:checked {
  background-color: #b4c0e2;
}

.switch-item ~ .control[type=checkbox]:checked:after {
  left: 39.1%;
}

.switch-item ~ .control[type=checkbox]:checked:active:after {
  margin-left: -8px;
}

.switch-item ~ .control[type=checkbox] ~ .label:before {
  content: "OFF";
}

.switch-item ~ .control[type=checkbox]:checked ~ .label:before {
  content: "ON";
}

.switch-item ~ .control[type=checkbox]:disabled {
  opacity: 0.3;
}

.switch-item ~ .control[type=checkbox]:disabled:active:after {
  padding-right: 0;
  margin-left: 0;
}

.nice-cb {
  padding: 10px 8px;
  background: #E8EAED;
  border-radius: 8px;
  max-height: 185px;
  overflow: auto;
  /* Track */
  /* Handle */
}
.nice-cb::-webkit-scrollbar {
  width: 10px;
}
.nice-cb::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.nice-cb::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}
.nice-cb .group-label {
  font-size: 14px;
  margin-bottom: 14px;
  display: block;
  font-weight: 600;
  padding-bottom: 7px;
  border-bottom: 1px solid #d0d0d0;
}
.nice-cb label {
  display: flex !important;
  align-items: center;
  margin-bottom: 10px !important;
}
.nice-cb label:before {
  content: "";
  display: flex;
  margin-right: 8px;
  width: 18px;
  height: 18px;
  background: #fff;
  align-items: center;
  justify-content: center;
}
.nice-cb [type=checkbox]:checked + label:before {
  content: "✓";
  font-size: 16px;
}
.nice-cb [type=checkbox] {
  position: absolute;
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  top: 0;
}

.a2lix_translationsFields .toggle-lang {
  display: none;
}
.a2lix_translationsFields .toggle-lang.show {
  display: block;
}

.ui__form label {
  display: block;
  font-size: 13px;
  line-height: 16px;
  color: #000;
  margin-bottom: 8px;
  font-weight: 500;
}
.ui__form input, .ui__form textarea {
  display: block;
  width: 100%;
  background: #fff;
  border: 1px solid #E8EAED;
  border-radius: 8px;
  padding: 12px 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000;
}
.ui__form input:focus, .ui__form textarea:focus {
  border-radius: 8px;
  box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.2);
}
.ui__form select {
  display: block;
  width: 100%;
  background: url("/public/admin/img/dropdown-arr.svg") no-repeat right 1rem center #fff;
  border-radius: 8px;
  padding: 12px 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000;
  appearance: none;
}
.ui__form select:focus {
  box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.2);
}
.ui__form textarea {
  max-width: 100%;
  min-width: 100%;
  min-height: 182px;
}
.ui__form .ui__2-col--aside .ui__form-row, .ui__form .fieldSet .ui__form-row {
  margin-bottom: 12px;
}
.ui__form .ui__2-col--aside .ui__form-controls .ui__button-delete, .ui__form .fieldSet .ui__form-controls .ui__button-delete {
  margin-left: 22px;
  margin-right: 10px;
}
.ui__form .ui__2-col--aside label, .ui__form .fieldSet label {
  margin-bottom: 6px;
}
.ui__form .ui__2-col--aside input, .ui__form .ui__2-col--aside textarea, .ui__form .fieldSet input, .ui__form .fieldSet textarea {
  background: #E8EAED;
  height: 42px;
  padding: 10px;
  font-size: 14px;
  line-height: 1;
}
.ui__form .ui__2-col--aside select, .ui__form .fieldSet select {
  font-size: 14px;
  height: 42px;
  padding: 8px 10px;
  background: url("/public/admin/img/dropdown-arr.svg") no-repeat right 1rem center #E8EAED;
}
.ui__form .ui__2-col--aside [type=date], .ui__form .ui__2-col--aside [type=time], .ui__form .fieldSet [type=date], .ui__form .fieldSet [type=time] {
  margin-bottom: 4px;
}
.ui__form .fieldSet .ui__form-row:last-child {
  margin-bottom: 0;
}

.ui__form-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}
.ui__form-row.--hidden {
  display: none !important;
}

.ui__form-controls {
  padding-top: 16px;
  border-top: 1px solid #E8EAED;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ui__form-controls [type=submit] {
  max-width: 200px;
  width: 100%;
  margin-right: 16px;
}
.ui__form-controls .ui__button-delete {
  margin-right: 16px;
}

.remove-item {
  display: block;
  margin-left: auto;
  margin-right: 10px;
}

.form__checkbox [type=checkbox] {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  background: #fff;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: 0;
  border: 0;
  padding: 0;
}
.form__checkbox [type=checkbox]:focus {
  outline: none;
  border: 0;
}
.form__checkbox [type=checkbox]:before {
  width: 24px;
  height: 24px;
  content: "";
  border: 1px solid #E8EAED;
  background: #fff;
  border-radius: 4px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form__checkbox [type=checkbox]:checked:before {
  content: url("/public/admin/img/check.svg");
}

.string-translation__form input {
  height: 38px;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 12px;
}
.string-translation__form .string-input {
  position: relative;
}
.string-translation__form .string-input .lang {
  position: absolute;
  top: 11px;
  font-size: 12px;
  font-weight: 500;
  right: 10px;
}

.form__filter-variants hr {
  height: 1px;
  display: block;
  width: 100%;
  background: #ccc;
  margin: 13px 0;
}
.form__filter-variants .field-wrapper {
  position: relative;
}
.form__filter-variants span.lang {
  font-size: 12px;
  font-weight: 500;
  position: absolute;
  right: 12px;
  bottom: 18px;
}
.form__filter-variants [type=text] {
  margin-bottom: 4px;
}

.thumb__input, .image__input {
  position: absolute;
  z-index: -9999;
  opacity: 0;
}

.thumb__window {
  width: 100%;
  height: 174px;
  background: #E8EAED;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.thumb__window picture {
  height: 100%;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.thumb__window:hover .thumb__placeholder {
  display: flex;
  background: rgba(255, 255, 255, 0.02);
}

.thumb__placeholder {
  transition: 0.3s background-color;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(1px);
}
.thumb__placeholder.uploaded {
  display: none;
}

.thumb__img {
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

.ui__image-upload img {
  max-width: 100%;
  height: auto;
  max-height: 100%;
}
.ui__image-upload picture {
  height: 100%;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ui__button-primary {
  background: #D8DFF4;
  border-radius: 200px;
  padding: 12px 16px;
  color: #000;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
}

.ui__button-add {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #000;
  background: #D8DFF4;
  border-radius: 200px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
}
.ui__button-add svg {
  margin-right: 12px;
}

.ui__button-archive, .ui__button-back {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #000;
  display: flex;
  align-items: center;
  background: #D8DFF4;
  border-radius: 200px;
  padding: 8px 16px;
}
.ui__button-archive svg, .ui__button-back svg {
  margin-right: 12px;
}

.ui__button-recover {
  padding: 12px 20px;
  border-radius: 200px;
  display: inline-flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  width: 40px;
}
.ui__button-recover svg {
  width: 16px;
  height: 16px;
  min-width: 16px;
}
.ui__button-recover .label {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #000;
  margin-left: 13px;
  margin-bottom: -2px;
  text-transform: uppercase;
  transition: all 0.3s ease;
  opacity: 0;
}
.ui__button-recover.hover {
  background: #313D49;
}
.ui__button-recover.hover .label {
  opacity: 1;
}

.fields__relation-select .hidden {
  display: none;
  visibility: hidden;
}

.fields {
  margin-top: 46px;
}

.field__group {
  padding-bottom: 24px;
  border-bottom: 1px solid #E8EAED;
  margin-bottom: 40px;
}
.field__group .thumb__window {
  background: #fff;
}
.field__group .thumb__window svg path {
  stroke: #000;
}

.field__group-name {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.01em;
  color: #000;
  display: flex;
  align-items: center;
  margin-bottom: 41px;
}
.field__group-name:before {
  content: "";
  display: block;
  background: #8296AA;
  border-radius: 1px;
  width: 4px;
  height: 26px;
  margin-right: 12px;
}

.field__repeater {
  padding-bottom: 24px;
  border-bottom: 0;
  margin-bottom: 40px;
}

.field__repeater-label {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 18px;
  border-bottom: 1px solid #d0d0d0;
  padding-bottom: 12px;
}

.field__repeater-row:not(:last-child) {
  border-bottom: 1px solid #d0d0d0;
  margin-bottom: 24px;
}

.repeater__row-delete {
  margin-bottom: 10px;
}

.filter__field {
  position: relative;
}
.filter__field .delete {
  position: absolute;
  top: 17px;
  right: 20px;
  z-index: 1;
}
.filter__field input {
  padding-right: 50px;
}

[data-content-editor] {
  padding: 36px 0;
  border: 1px solid #cfcfcf;
  border-radius: 8px;
  box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.1);
  background: #fff;
}

.blocks-container {
  display: flex;
  justify-content: space-between;
}

.child-block {
  border: 1px solid #eee;
  margin-bottom: 10px;
  box-shadow: 0 6px 18px rgba(232, 237, 250, 0.5019607843);
  min-height: 80px;
  background-color: #fff;
  width: 48%;
  border-radius: 8px;
  padding: 12px;
}

.child-block .ce-block__content {
  max-width: 100%;
}

.child-block > div {
  height: 100%;
}

.child-block:empty:after {
  content: "+";
  display: block;
  font-size: 24px;
  margin: 0 auto;
}

.ce-editorjsColumns_col {
  border: 1px solid #eee;
  border-radius: 5px;
  gap: 10px;
  padding-top: 10px;
}

.child-block:focus-within {
  box-shadow: 0 6px 18px rgba(232, 237, 250, 0.5019607843);
}

.ce-block__content {
  max-width: calc(100% - 140px);
}
.ce-block__content h2 {
  font-size: 24px;
  font-weight: 600;
}
.ce-block__content h3 {
  font-size: 20px;
  font-weight: 600;
}
.ce-block__content h4 {
  font-size: 16px;
  font-weight: 600;
}

.ce-toolbar__content {
  max-width: calc(100% - 124px);
}

dialog {
  border: 1px solid #fff;
  padding: 30px !important;
  border-radius: 14px;
  margin: auto;
}

.ce-link-autocomplete__items {
  max-height: 259px;
  overflow: scroll;
}

.gg_gallery-field .filepond--panel-root {
  background-color: unset !important;
}
.gg_gallery-field .filepond--panel {
  background-color: #fff !important;
  border: 1px solid #d0d0d0;
}
.gg_gallery-field .filepond--file span, .gg_gallery-field p {
  color: #fff;
}
.gg_gallery-field .filepond--item {
  width: calc(50% - 0.5em);
}
@media (min-width: 30em) {
  .gg_gallery-field .filepond--item {
    width: calc(50% - 0.5em);
  }
}
@media (min-width: 50em) {
  .gg_gallery-field .filepond--item {
    width: calc(33.33% - 0.5em);
  }
}

.nav-menu-edit .drop-area {
  border: 1px dashed #d0d0d0;
  background: #E8EAED;
  padding: 12px 12px 0;
  border-radius: 8px;
  margin-bottom: 15px;
  min-height: 60px;
}
.nav-menu-edit .nav__entity-group {
  background: #fff;
  border: 1px solid #d0d0d0;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 14px;
}
.nav-menu-edit .nav__entity-group input[type=text] {
  background: #E8EAED;
  font-size: 12px;
  padding: 8px;
  margin-bottom: 8px;
}
.nav-menu-edit .nav__entity-group .label {
  font-size: 14px;
  padding-bottom: 8px;
  border-bottom: 1px solid #d0d0d0;
  margin-bottom: 12px;
  font-weight: 700;
  color: #000;
}
.nav-menu-edit .nav__entity-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.nav-menu-edit .nav__entity-item .checkbox {
  max-width: 18px;
  height: 14px;
  width: 14px;
  margin-right: 8px;
  background: #fff;
  position: absolute;
  opacity: 0;
}
.nav-menu-edit .nav__entity-item .checkbox:checked ~ label:before {
  content: url("/public/admin/img/check.svg");
}
.nav-menu-edit .nav__entity-item label {
  margin-bottom: 0;
  font-weight: 500;
  position: relative;
  display: flex;
  align-items: center;
}
.nav-menu-edit .nav__entity-item label:before {
  width: 18px;
  height: 18px;
  content: "";
  border: 1px solid #d0d0d0;
  background: #fff;
  border-radius: 4px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-menu-edit .nav__entity-list {
  padding-bottom: 8px;
  border-bottom: 1px solid #d0d0d0;
  margin-bottom: 12px;
  max-height: 132px;
  overflow: auto;
}
.nav-menu-edit .nav__entity-add {
  border-radius: 19px;
  font-size: 14px;
  color: #1890FF;
  border: 1px solid #1890FF;
  padding: 6px 9px;
  display: block;
  margin-left: auto;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
}
.nav-menu-edit .nav-item__container .children-container {
  padding: 8px 0 0 18px;
  margin-bottom: 8px;
}
.nav-menu-edit .nav-item {
  background: #fff;
  padding: 12px;
  box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: relative;
}
.nav-menu-edit .nav-item input {
  background: #E8EAED;
  margin-bottom: 12px;
}
.nav-menu-edit .nav-item .input-row:not(:last-child) {
  margin-bottom: 8px;
}
.nav-menu-edit .nav-item .input-row.--hidden {
  display: none;
}

.nav-item__buttons {
  display: flex;
  justify-content: space-between;
  padding: 8px 12px;
}
.nav-item__buttons .see-page {
  color: #1890FF;
  font-size: 12px;
  font-weight: 500;
}

.nav-item__delete {
  margin-left: auto;
}

nav.pagination {
  margin-top: 32px;
  display: flex;
  justify-content: center;
}
nav.pagination .pagination__item {
  display: block;
}
nav.pagination .pagination__item:not(:last-child) {
  margin-right: 10px;
}
nav.pagination a.pagination__item {
  color: #1890ff;
  font-size: 16px;
  font-weight: 500;
}
nav.pagination span.pagination__item {
  color: #504d4d;
}

.filter__category {
  max-width: 180px;
  font-weight: 600 !important;
}

.order__head {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.order__head .order__status {
  padding: 12px 15px;
  max-width: 220px;
  font-size: 16px;
}

.order__page {
  background: #fff;
  border-radius: 12px;
  padding: 40px 20px;
  box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
}
.order__page .order__products {
  width: 50%;
  padding-right: 40px;
}
.order__page .order__info {
  width: 50%;
  padding-left: 40px;
  border-left: 1px solid #ccc;
}
.order__page .order__info .line {
  margin-bottom: 10px;
  font-weight: 500;
}
.order__page h2 {
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.order__page .product {
  display: flex;
  margin-bottom: 10px;
}
.order__page .product .thumb {
  max-width: 80px;
  margin-right: 10px;
  border-radius: 4px;
  overflow: hidden;
}
.order__page .product .title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 6px;
}
.order__page .product .qty, .order__page .product .taste {
  font-size: 14px;
}
.order__page .product .qty {
  margin-bottom: 2px;
}
.order__page .controls {
  margin-top: 24px;
  display: flex;
}

.order__in-progress, .order__done {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 15px;
  font-size: 14px;
  max-width: 200px;
  border-radius: 8px;
  font-weight: 500;
  color: #000000;
}

.order__in-progress {
  background: #f2e373;
  margin-right: 10px;
}

.order__done {
  background: #98e2a6;
}

.order__status {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 10px;
  font-size: 12px;
  max-width: 132px;
  border-radius: 8px;
  font-weight: 500;
}
.order__status.unprocessed {
  background: #e7e8e9;
}
.order__status.in-progress {
  background: #f2e373;
}
.order__status.done {
  background: #98e2a6;
}

.switch-item {
  display: block;
}

.switch-item .label {
  margin-left: 4px;
  vertical-align: middle;
  font-size: 16px;
  color: #000;
}

.switch-item ~ .control[type=checkbox] {
  appearance: none;
  position: relative;
  width: 52px;
  height: 34px;
  padding: 3px;
  border-radius: 34px;
  background-color: #aaa;
  vertical-align: middle;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.1, 1.4);
  border: 1px solid #E8EAED;
  box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.1);
}

.switch-item ~ .control[type=checkbox]:after {
  content: "";
  position: relative;
  display: block;
  left: 0;
  width: 60.9%;
  height: 100%;
  border-radius: 28px;
  background-color: #fff;
  transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), padding 0.3s ease, margin 0.3s ease;
  box-sizing: content-box;
}

.switch-item ~ .control[type=checkbox]:active:after {
  padding-right: 8px;
}

.switch-item ~ .control[type=checkbox]:checked {
  background-color: #b4c0e2;
}

.switch-item ~ .control[type=checkbox]:checked:after {
  left: 39.1%;
}

.switch-item ~ .control[type=checkbox]:checked:active:after {
  margin-left: -8px;
}

.switch-item ~ .control[type=checkbox] ~ .label:before {
  content: "OFF";
}

.switch-item ~ .control[type=checkbox]:checked ~ .label:before {
  content: "ON";
}

.switch-item ~ .control[type=checkbox]:disabled {
  opacity: 0.3;
}

.switch-item ~ .control[type=checkbox]:disabled:active:after {
  padding-right: 0;
  margin-left: 0;
}

.nice-cb {
  padding: 10px 8px;
  background: #E8EAED;
  border-radius: 8px;
  max-height: 185px;
  overflow: auto;
  /* Track */
  /* Handle */
}
.nice-cb::-webkit-scrollbar {
  width: 10px;
}
.nice-cb::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.nice-cb::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}
.nice-cb .group-label {
  font-size: 14px;
  margin-bottom: 14px;
  display: block;
  font-weight: 600;
  padding-bottom: 7px;
  border-bottom: 1px solid #d0d0d0;
}
.nice-cb label {
  display: flex !important;
  align-items: center;
  margin-bottom: 10px !important;
}
.nice-cb label:before {
  content: "";
  display: flex;
  margin-right: 8px;
  width: 18px;
  height: 18px;
  background: #fff;
  align-items: center;
  justify-content: center;
}
.nice-cb [type=checkbox]:checked + label:before {
  content: "✓";
  font-size: 16px;
}
.nice-cb [type=checkbox] {
  position: absolute;
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  top: 0;
}

.seo-meta__fields.-hidden {
  display: none;
}

.seo-meta__controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.seo-meta__controls .lang-switch {
  display: flex;
  background: #fff;
  padding: 3px;
  border: 1px solid #d0d0d1;
  border-radius: 6px;
}
.seo-meta__controls .lang-switch .lang {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 30px;
  color: #000000;
  border-radius: 6px;
  cursor: pointer;
}
.seo-meta__controls .lang-switch .lang.active {
  background: #cfdbfe;
}

.status .status-label {
  display: inline-block;
  padding: 8px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
  color: #ffffff;
}
.status .pending {
  background: #e8c657;
}
.status .finished {
  background: #369763;
}
.status .error {
  background: #B0413E;
}

.error-log {
  background: #fff;
  border-radius: 8px;
  padding: 32px 24px;
  max-height: 600px;
  overflow: scroll;
}
.error-log p {
  margin-bottom: 12px;
  font-weight: 500;
}

.messages h2 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 24px;
}
.messages .ui__listing {
  margin-bottom: 32px;
}

.message .window {
  padding: 40px;
  background: #fff;
  border-radius: 32px;
}
.message h2 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 18px;
}
.message p {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 32px;
}
.message .message-photos {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.message .message-photos img {
  max-width: 240px;
  margin-right: 10px;
  height: auto;
}

.glossary-modal {
  position: fixed;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(3);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99999;
  display: none;
}
.glossary-modal.opened {
  display: flex;
}

.glossary__item .value {
  position: relative;
}
.glossary__item .value .locale {
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 12px;
  font-weight: 500;
}

#add-glossary-item {
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 40px;
  border-radius: 4px;
  min-width: 414px;
}
#add-glossary-item button {
  justify-content: center;
  margin-top: 24px;
}
#add-glossary-item input {
  height: 42px;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 12px;
  background: #f6f9fb;
  border: 1px solid #ccc;
  padding: 9px 15px;
  box-sizing: border-box;
}

.form__add-review {
  display: flex;
  background: #fff;
  padding: 18px 17px;
  border-radius: 6px;
}
.form__add-review .thumb {
  width: 30%;
}
.form__add-review .meta {
  width: 70%;
  padding-left: 18px;
}
.form__add-review label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  display: block;
  margin-bottom: 8px;
}
.form__add-review [type=file] {
  width: 10px !important;
}
.form__add-review input, .form__add-review textarea, .form__add-review select {
  background: #E8EAED;
  border: 1px solid #e5e7ea;
  display: block;
  width: 100%;
  border-radius: 6px;
  margin-bottom: 8px;
  padding: 4px 12px;
}
.form__add-review textarea {
  height: 97px;
  max-width: 100%;
  min-width: 100%;
}
.form__add-review input, .form__add-review select {
  height: 42px;
}
.form__add-review .thumb__window {
  margin-bottom: 7px;
}
.form__add-review [type=submit] {
  background: #D8DFF4;
  border: 0;
  border-radius: 6px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  font-size: 14px;
  font-weight: 500;
  margin-top: 24px;
  margin-left: auto;
}

.writer__review {
  display: flex;
  background: #fff;
  border-radius: 6px;
  padding: 18px;
  margin-bottom: 18px;
}
.writer__review .thumb {
  max-width: 80px;
  margin-right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  width: 80px;
  height: 80px;
}
.writer__review .meta {
  max-width: calc(100% - 104px);
  width: 100%;
}
.writer__review .rating {
  list-style: none;
  display: flex;
}
.writer__review .rating .disabled svg {
  fill: #888888;
}
.writer__review .rating svg {
  width: 14px;
  height: 14px;
  fill: gold;
}
.writer__review .name {
  font-size: 14px;
  font-weight: 500;
}
.writer__review .text {
  font-size: 14px;
  color: #232323;
  margin-bottom: 8px;
}
.writer__review .date {
  font-size: 14px;
  color: #484747;
  margin-left: auto;
}
.writer__review .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.writer__review .delete {
  cursor: pointer;
}

.article__reviews .review__item {
  background: #fff;
  border-radius: 6px;
  margin-bottom: 8px;
  font-size: 14px;
  padding: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.article__reviews .review__item.approved {
  background: rgba(158, 248, 174, 0.4117647059);
}
.article__reviews .review__item div {
  margin-bottom: 4px;
}
.article__reviews .review__item .controls {
  display: flex;
  align-items: center;
}
.article__reviews .review__item .approve {
  color: #ffffff;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0E9C60;
  border-radius: 4px;
  margin-right: 10px;
}
.article__reviews .review__item span {
  font-weight: 500;
}

.tab__item {
  width: 100%;
  justify-content: space-between;
  margin-bottom: 24px;
}
.tab__item .cb-group {
  background: #fff;
  border-radius: 8px;
  padding: 14px;
  max-height: 238px;
  overflow: auto;
}
.tab__item .cb {
  display: flex;
  position: relative;
  margin-bottom: 4px;
}
.tab__item .cb input {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.tab__item .cb label {
  display: flex;
  align-items: center;
}
.tab__item .cb input + label:before {
  content: "";
  width: 18px;
  height: 18px;
  border: 1px solid #000;
  margin-right: 10px;
}
.tab__item .cb input:checked + label:after {
  content: "✓";
  font-weight: 600;
  font-size: 18px;
  position: absolute;
  top: 1px;
  left: 1px;
}