@use 'variables';

.gg_gallery-field{
  .filepond--panel-root {
   background-color: unset!important;
  }

  .filepond--panel{
    background-color: #fff!important;
    border: 1px solid variables.$borderSecondary;
  }

  .filepond--file span, p{
    color: #fff;
  }

  .filepond--item {
    width: calc(50% - 0.5em);
  }

  @media (min-width: 30em) {
    .filepond--item {
      width: calc(50% - 0.5em);
    }
  }

  @media (min-width: 50em) {
    .filepond--item {
      width: calc(33.33% - 0.5em);
    }
  }
}