.status{
  .status-label{
    display: inline-block;
    padding: 8px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 4px;
    color: #ffffff;
  }

  .pending{
    background: #e8c657;
  }

  .finished{
    background: #369763;
  }

  .error{
    background: #B0413E;
  }
}

.error-log{
  background: #fff;
  border-radius: 8px;
  padding: 32px 24px;
  max-height: 600px;
  overflow: scroll;

  p{
    margin-bottom: 12px;
    font-weight: 500;
  }
}